<template>
  <div class="menusWrap">
    <div class="tree">
      <el-input placeholder="输入关键字进行过滤" v-model="filterText">
      </el-input>
      <el-tree
        class="filter-tree"
        :data="menusTreeData"
        :props="defaultProps"
        node-key="id"
        :current-node-key="currentNodeKey"
        default-expand-all
        :filter-node-method="filterNode"
        highlight-current
        ref="tree"
        @node-click="nodeClickHandler"
        check-on-click-node
      >
      </el-tree>
    </div>

    <div class="childMenusTable">
      <div class="batchOperations">
        <el-button
          icon="el-icon-plus"
          type="primary"
          @click="$router.push('/menus/add')"
          >新增
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-delete"
          plain
          :disabled="selectedRowsIds.length <= 0"
          @click="batchDeleteMenus([])"
          >批量删除
        </el-button>
      </div>
      <el-table
        :data="childMenusData.result"
        class="rolesTable"
        border
        @selection-change="handleSelectionChange"
        >>
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          v-for="c in tableColumns"
          :key="c.prop"
          :label="c.label"
          :width="c.width"
        >
          <template slot-scope="scope">
            <span v-if="c.prop == 'update_time'">
              {{ dateTransform(scope.row[c.prop]) }}
            </span>
            <el-switch
              v-else-if="c.prop == 'status'"
              v-model="scope.row[c.prop]"
            >
            </el-switch>
            <div v-else-if="c.prop == 'operations'">
              <el-button
                size="mini"
                type="primary"
                @click="$router.push(`/menus/edit?id=${scope.row.id}`)"
                >编辑
              </el-button>

              <el-button
                type="danger"
                size="mini"
                @click="deleteMenu([scope.row.id])"
                >删除
              </el-button>
            </div>
            <span style="margin-left: 10px" v-else>{{
              scope.row[c.prop]
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { dateTransform } from "@/utils/utils";
export default {
  data() {
    return {
      tableColumns: [
        {
          label: "菜单名称",
          prop: "menu_name",
        },
        {
          label: "状态",
          prop: "status",
        },
        {
          label: "更新时间",
          prop: "update_time",
        },
        {
          label: "菜单顺序",
          prop: "order_num",
        },
        {
          label: "备注",
          prop: "remark",
        },
        {
          label: "操作",
          width: 150,
          prop: "operations",
        },
      ],
      childMenusData: {},
      menusTreeData: [],
      filterText: "",
      currentNodeKey: "10000",
      defaultProps: {
        children: "children",
        label: "menu_name",
      },
      selectedRowsIds: [],
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  mounted() {
    this.getAllMenusTreeData();
  },
  methods: {
    dateTransform,
    handleSelectionChange(v) {
      this.selectedRowsIds = v.map((item) => item.id);
    },
    batchDeleteMenus() {
      if (_.isEmpty(this.selectedRowsIds)) {
        return;
      }
      this.deleteMenu(this.selectedRowsIds);
    },
    deleteMenu(ids) {
      this.$request.deleteMenu({ ids }).then((res) => {
        this.getAllMenusTreeData();
      });
    },
    nodeClickHandler(node) {
      this.getChildMenusList(node.id);
    },
    getChildMenusList(id) {
      this.$request.getChildMenusList({ parent_id: id }).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        const data = res?.data || {};
        this.childMenusData = {
          ...data,
          result: (data.result || []).map((item) => {
            return {
              ...item,
              status: JSON.parse(item.status),
            };
          }),
        };
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.menu_name.indexOf(value) !== -1;
    },
    getAllMenusTreeData() {
      this.$request.getAllMenusTreeData({}).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        this.menusTreeData = [res.data];
        this.getChildMenusList(res?.data?.id);
      });
    },
  },
};
</script>

<style lang='scss'>
.menusWrap {
  background-color: #fff;
  min-height: 60%;
  display: flex;
  .tree {
    width: 300px;
    flex-shrink: 0;
    box-shadow: 10px 0 10px 0 rgb(235 238 245 / 71%), 0 0.01rem 0 0 #ebeef5;
    padding: 24px;
    .el-tree-node__content {
      height: 40px;
    }
    .el-input--small {
      margin-bottom: 12px;
    }
  }
  .childMenusTable {
    // flex: 1;
    width: calc(100% - 300px);
    .batchOperations {
      padding: 12px;
    }
  }
}
</style>